import { Component } from '@angular/core';
import { ProjectService } from '../../service/views-services/project.service';
import { LocalStorageService } from '../../service/views-services/localstorage.service';
import { CurrentProjectService } from '../../service/views-services/current-project.service';
import { Project } from '../../models/views-models/project.model';
import { MatDialog } from '@angular/material/dialog';
import { PopUpMessagesComponent } from '../pop-up-messages/pop-up-messages.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProjectStatus } from '../../models/views-models/projectStatus.model';

@Component({
  selector: 'app-version-buttons',
  templateUrl: './version-buttons.component.html',
  styleUrls: ['./version-buttons.component.scss']
})
export class VersionButtonsComponent {
    project: Project;
    isChangeVersion: boolean = false;
    selectedVersion: string = 'BUILDING';
    messageLoader: string;
    dialogRef: any;
    isSpinnerActive: boolean = false;
    loaderMessage: string = '';
    projectVersion: Project;
    subs: Array<Subscription> = [];
    projectStatus: ProjectStatus = new ProjectStatus();
    projectVersions: Array<Project>;
    showTooltip = false;
    tooltipMessage: string;

    constructor(
        private projectService: ProjectService,
        private lsService: LocalStorageService,
        public currentProjectService: CurrentProjectService,
        private dialog: MatDialog,
        private router: Router
    ) {}

    openPopup(options) {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            minWidth: options.minWidth ? options.minWidth : 'auto',
            height: 'auto',
            data: options.data,
        });
    }

    ngOnInit() {
        this.selectedVersion = this.currentProjectService.isBuildingMode() ? 'BUILDING' : 'RUNNING';
        this.project = this.currentProjectService.getCurrentProject();
        this.projectService.getProjectVersions(this.project.mainProjectId).subscribe(p =>{
            this.projectVersions = p;
        });
    }

    apply(project: Project) {
        this.startSpinner(`Carregando versão ${this.selectedVersion.charAt(0).toUpperCase() + this.selectedVersion.slice(1).toLowerCase()}...`);
        this.projectService.applyVersion(project).subscribe({
            next: (isVersionapplied) => {
                if (isVersionapplied) {
                    this.lsService.reset(project);
                    this.lsService.triggerLocalStorageChanges(project);
                    this.currentProjectService.setCurrentProject(project);
                    this.currentProjectService.goReload();
                    this.stopSpinner();
                }
            },
            error: (err) => {
                let conf = {
                    component: PopUpMessagesComponent,
                    panelClass: 'pop-up-dialog-container',
                    data: {
                        imageUrl: 'assets/images/status/errorMessage.svg',
                        title: 'Erro ao Carregar Versão',
                        subTitle: 'Não foi possível carregar a versão ' + project.versionType,
                        optionalText: 'Tente novamente mais tarde',
                        typePopUp: 'titlePopUpInformationMessage',
                    },

                };
                this.stopSpinner();
                this.openPopup(conf);
                this.verifyVersion();
            },
        });
    }

    selectVersion() {
        if(this.projectVersions?.length > 1){
            this.verifyVersion();
            this.apply(this.project);
        }
    }

    verifyVersion(){
        this.selectedVersion = this.selectedVersion === 'BUILDING' ? 'RUNNING' : 'BUILDING';

        if (
            this.selectedVersion === 'RUNNING' ||
            this.selectedVersion === 'BUILDING' ||
            this.selectedVersion === 'OLD'
        ) {
            this.project = this.projectVersions.find(p => p.versionType == this.selectedVersion);
        }
    }

    startSpinner(text: string) {
        this.loaderMessage = text;
        this.isSpinnerActive = true;
    }

    stopSpinner() {
        this.isSpinnerActive = false;
    }

    verifyProjectVersions(){
        return this.projectVersions?.length;
    }

    onHover(isHovering: boolean) {
        if(isHovering){
            this.tooltipMessage = "A troca de versão está desabilitada porque ainda não há uma versão \'RUNNING\' disponível. Realize o deploy para habilitá-la.";
        }
        this.showTooltip = isHovering;
    }
}
