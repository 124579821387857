export class Views {
    public static views = [
        {
            name: 'Projetos',
            url: '/projetos',
            icon: 'layers-icon/layers.svg',
            source: 'assets/images/icons/',
            sidebarSection: "cadastro"
        },
        {
            name: 'Entradas',
            url: '/entradas',
            icon: 'input_circle',
            source: 'material-symbols-outlined',
            sidebarSection: "cadastro"
        },
        {
            name: 'Tratamento de Dados',
            url: '/tratamento-de-dados',
            icon: 'timeline',
            source: 'material-symbols-outlined',
            sidebarSection: "cadastro"
        },
        {
            name: 'Regras Fuzzy',
            url: '/regras-fuzzy',
            icon: 'fuzzy-rules-icon/fuzzy-rules.svg',
            source: 'assets/images/icons/',
            sidebarSection: "cadastro"
        },
        {
            name: 'Variáveis Calculadas',
            url: '/variaveis-calculadas',
            icon: 'function',
            source: 'material-symbols-outlined',
            sidebarSection: "cadastro"
        },
        {
            name: 'Setpoints',
            url: '/setpoints',
            icon: 'setpoint-icon/setpoint.svg',
            source: 'assets/images/icons/',
            sidebarSection: "cadastro"
        },
        {
            name: 'Saídas',
            url: '/saidas',
            icon: 'output_circle',
            source: 'material-symbols-outlined',
            sidebarSection: "cadastro"
        },
        {
            name: 'Acompanhamento de Regras',
            url: '/acompanhamento-de-regras',
            icon: 'tracking-rules-icon/tracking-rules.svg',
            source: 'assets/images/icons/',
            sidebarSection: "comissionamento-e-sustentacao"
        },
        {
            name: 'Acompanhamento de Setpoints',
            url: '/acompanhamento-de-setpoints',
            icon: 'tracking-setpoint-icon/tracking-setpoint.svg',
            source: 'assets/images/icons/',
            sidebarSection: "comissionamento-e-sustentacao"
        },

    ];

    public static controlRules = {
        name: 'Regras Fuzzy',
        menuName: 'Regras Fuzzy',
        url: 'regras-fuzzy',
    };

    public static saveControlRules = {
        name: 'Regras',
        menuName: 'Regras',
        url: 'salvar-regras',
    };

    public static projects = {
        name: 'Seleção de Projetos',
        menuName: 'Projetos',
        url: 'projetos',
    };

    public static setpointsList = {
        name: 'Setpoints',
        menuName: 'Setpoints',
        url: 'setpoints',
    };

    public static saveSetpoint = {
        name: 'Setpoints',
        menuName: 'Setpoints',
        url: 'salvar-setpoint',
    };

    public static processInputsList = {
        name: 'Entradas',
        menuName: 'Entradas',
        url: 'entradas',
    };

    public static processOutputsList = {
        name: 'Saídas',
        menuName: 'Saídas',
        url: 'saidas',
    };

    public static dataTreatment = {
        name: 'Tratamento de Dados',
        menuName: 'Tratamento de Dados',
        url: 'tratamento-de-dados',
    };

    public static rulesTracking = {
        name: 'Acompanhamento de Regras',
        menuName: 'Acompanhamento de Regras',
        url: 'acompanhamento-de-regras',
    };

    public static setpointsTracking = {
        name: 'Acompanhamento de Setpoints',
        menuName: 'Acompanhamento de Setpoints',
        url: 'acompanhamento-de-setpoints',
    };

    public static usersList = {
        name: 'Usuários',
        menuName: 'Usuários',
        url: 'usuarios',
    };

    public static equationList = {
        name: 'Variáveis Calculadas',
        menuName: 'Variáveis Calculadas',
        url: 'variaveis-calculadas',
    };

    public static homePage = 'entradas';
}
