<div class="container">
    <mat-card appearance="outlined">
        <mat-card-header fxLayout="row">
            <mat-card-title> Projetos Cadastrados </mat-card-title>
            <div class="projects-found" fxLayout="column" fxLayoutAlign="center">
                {{ projectsFound }} Projetos Encontrados
            </div>
            <div fxFlex>
                <button
                    class="customButton add-button"
                    mat-raised-button
                    type="button"
                    (click)="addProject()"
                    *ngIf="user.permissions.canCreate && getCurrentProjectVersionType() === 'BUILDING'"
                    data-cy="add"
                >
                    CADASTRAR PROJETO
                </button>
            </div>
        </mat-card-header>
    </mat-card>
    <div class="projects" *ngFor="let project of projects">
        <app-project-card
            [user]="user"
            [project]="project"
            (reloadProjects)="reloadRequired($event)"
        ></app-project-card>
    </div>
</div>
