import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { SideNavService } from 'src/app/shared/service/views-services/sideNavService.service';
import { getUserData } from 'src/app/shared/utils/userRole';
import { LoginService } from 'src/app/views/login/login.service';
import { LayoutService } from '../../../service/layout/layout.service';
import { ThemeService } from '../../../service/theme/theme.service';
import { PopUpMessagesComponent } from '../../pop-up-messages/pop-up-messages.component';

@Component({
    selector: 'app-adm-layout',
    templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    public isModuleLoading: boolean = false;
    private layoutConfSub: Subscription;
    private routerEventSub: Subscription;
    private isMobile;
    private bodyPS: PerfectScrollbar;
    private headerFixedBodyPS: PerfectScrollbar;
    public layoutConf: any = {};
    public onSideNavChange: boolean;

    private subs: Array<Subscription> = [];
    user: any;
    dialogRef: any;
    hour = 3600;
    minute = 60;
    expirationTime: any;

    constructor(
        private router: Router,
        public themeService: ThemeService,
        private sidenavService: SideNavService,
        private layout: LayoutService,
        private idle: Idle,
        public dialog: MatDialog,
        public loginService: LoginService
    ) {
        this.subs;
    }

    ngOnInit() {
        // Set time to wait until consider user idle
        this.startIdle();

        //this.reset();
        this.subs.push(
            this.sidenavService.sideNavState.subscribe((res) => {
                this.onSideNavChange = res;
            })
        );

        this.layoutConf = this.layout.layoutConf;
        this.isMobile = this.isSm();
        this.layout.publishLayoutChange({
            isMobile: this.isMobile,
            sidebarStyle: 'closed',
        });
        // FOR MODULE LOADER FLAG
        this.subs.push(
            this.router.events.subscribe((event) => {
                if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
                    this.isModuleLoading = true;
                }
                if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
                    this.isModuleLoading = false;
                }
            })
        );
    }
    startIdle() {
        this.configureIdle();
        this.subs.push(this.idle.onTimeout.subscribe(() => this.openDialog()));
        this.subs.push(this.idle.onInterrupt.subscribe((e) => this.refreshToken()));
        this.idle.watch();
    }

    //Create a basic Idle Config
    configureIdle() {
        this.idle.clearInterrupts();
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.setIdle(this.hour);
    }

    refreshToken() {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            let username = currentUser.username;
            let token = currentUser.token;
            this.calculateExpiration();

            if (this.expirationTime <= this.minute) {
                this.loginService.refreshToken(username, token).subscribe(() => (this.user = getUserData()));
                console.log('Renew Refresh Token');
            }
        }
    }

    calculateExpiration() {
        let userData = JSON.parse(localStorage.getItem('currentUser'));
        let expirationDate = new Date(userData.expirationDate).valueOf();
        let dateNow = new Date().valueOf();
        this.expirationTime = Math.round((expirationDate - dateNow) / 1000);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = this.isSm();
        this.layout.publishLayoutChange({
            isMobile: this.isMobile,
            sidebarStyle: this.isMobile ? 'closed' : 'full',
        });
    }

    onResized() {
        this.bodyPS.update();
    }

    ngAfterViewInit() {
        this.layoutConfSub = this.layout.layoutConf$.subscribe((change) => {
            this.initBodyPS(change);
        });
    }

    initBodyPS(layoutConf: any = {}) {
        if (layoutConf.navigationPos === 'side' && layoutConf.topbarFixed) {
            if (this.bodyPS) {
                this.bodyPS.destroy();
            }
            if (this.headerFixedBodyPS) {
                this.headerFixedBodyPS.destroy();
            }
            this.headerFixedBodyPS = new PerfectScrollbar('.rightside-content-hold', {
                suppressScrollX: true,
            });
        } else {
            if (this.bodyPS) {
                this.bodyPS.destroy();
            }
            if (this.headerFixedBodyPS) {
                this.headerFixedBodyPS.destroy();
            }
            this.bodyPS = new PerfectScrollbar('.main-content-wrap', {
                suppressScrollX: true,
            });
        }
    }

    ngOnDestroy() {
        if (this.layoutConfSub) {
            this.layoutConfSub.unsubscribe();
        }
        if (this.routerEventSub) {
            this.routerEventSub.unsubscribe();
        }

        this.subs.forEach((e) => e.unsubscribe());
        this.idle.ngOnDestroy();
    }

    closeSidebar() {
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed',
        });
    }

    isSm() {
        return window.matchMedia(`(max-width: 959px)`).matches;
    }

    openDialog() {
        this.dialogRef = this.dialog.open(PopUpMessagesComponent, {
            panelClass: 'pop-up-dialog-container',
            width: 'auto',
            height: 'auto',
            data: {
                subTitle: 'Sessão expirada por inatividade, por favor faça login novamente',
                typePopUp: 'titlePopUpConfirmationMessage'
            },
        });
        this.dialogRef.afterClosed().subscribe((response) => {
            this.dialog.closeAll();
            this.loginService.logout();
            this.router.navigate(['/login']);
        });
    }
}
