import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './../../service/navigation/navigation.service';
// import PerfectScrollbar from 'perfect-scrollbar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { animateText, onSideNavChange } from 'src/app/animations/animations';
import { getUserData } from 'src/app/shared/utils/userRole';
import { Project } from '../../models/views-models/project.model';
import { ThemeService } from '../../service/theme/theme.service';
import { LocalStorageService } from '../../service/views-services/localstorage.service';
import { SideNavService } from '../../service/views-services/sideNavService.service';
import { UserService } from '../../service/views-services/user.service';
import { getProject } from '../../utils/projectUtils';
import { Views } from '../../utils/views-urls';
import { AdminSettingsComponent } from '../views-components/admin-settings/admin-settings.component';
import { UserDialogComponent } from '../views-components/user-dialog/user-dialog.component';
import { CurrentProjectService } from '../../service/views-services/current-project.service';

@Component({
    selector: 'app-sidebar',
    styleUrls: ['./sidebar.component.scss'],
    templateUrl: './sidebar.component.html',
    animations: [onSideNavChange, animateText],
})
export class SidebarComponent implements OnInit, OnDestroy {
    // private sidebarPS: PerfectScrollbar;
    views = Views.views;
    pageTitle: string;

    public sideNavState: boolean = false;
    public linkText: boolean = false;

    public menuItems: any[];
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    project: Project;
    user: any;
    role: string;
    currentUser: any;
    dialogRef: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private navService: NavigationService,
        private userService: UserService,
        private sidenavService: SideNavService,
        public themeService: ThemeService,
        public currentProjectService: CurrentProjectService,

        public dialog: MatDialog
    ) {

        
        currentProjectService.observeCurrentProject().subscribe((project) => {
            this.checkProject(project);
        });
    }

    ngOnInit() {
        this.user = getUserData();
        this.checkProject(this.currentProjectService.getCurrentProject());
        this.role = this.translateRole();
        this.pageTitle = this.route.snapshot.firstChild.data['menuTitle'];
        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
        this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
            this.menuItems = menuItem;
            // Checks item list has any icon type.
            this.hasIconTypeMenuItem = !!this.menuItems.filter((item) => item.type === 'icon').length;
        });
    }

    translateRole() {
        if (this.user.isSuperUser) {
            return 'Global';
        } else if (this.user.permissions.canCreate) {
            return 'Administrador';
        } else if (this.user.permissions.canUpdate) {
            return 'Sustentação';
        } else {
            return 'Visualização';
        }
    }

    checkProject(project: Project) {
        this.project = project
        if (!this.project) {
            this.views = this.views.filter((view) => view.name == Views.projects.menuName);
        } else {
            this.views = Views.views;
        }
    }

    onSidenavToggle() {
        let timeout = !this.sideNavState ? 250 : 150;
        this.sideNavState = !this.sideNavState;

        setTimeout(() => {
            this.linkText = this.sideNavState;
        }, timeout);
        this.sidenavService.sideNavState.next(this.sideNavState);
    }

    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }

    isActive(page) {
        if (page == this.pageTitle) {
            return 'active';
        }
    }

    navigateTo(page) {
        this.router.navigate([page]);
    }

    navigateToUsers() {
        this.router.navigate([Views.usersList.url]);
    }

    openSettings() {
        let conf = {
            component: AdminSettingsComponent,
            minWidth: 'auto',
            height: 'auto',
            data: '',
        };
        this.openDialog(conf);
    }

    getProjectVersionType() {
        return this.currentProjectService.getCurrentProject().versionType;
    }

    openDialog(options: any): void {
        this.dialogRef = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            minWidth: options.minWidth,
            height: options.height,
            maxHeight: 'auto',
            data: options.data,
        });
    }

    filterViews(section: string): Views {
        return this.views.filter((view) => view.sidebarSection === section)
    }
}
