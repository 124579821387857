<div
    fxLayout="row"
    class="ihm-image-pop-up-header"
    [ngClass]="data.typePopUp === 'informationMessage' ? 'popUpInformationMessage' : 'popUpConfirmationMessage'"
>
    <span
        mat-icon-button
        *ngIf="data.typePopUp === 'titlePopUpInformationMessage'"
        class="close-button"
        tabindex="-1"
        (click)="close()"
    >
        <mat-icon>close</mat-icon>
    </span>
    <div class="ihm-image-pop-up-image-content">
        <div [ngClass]="data.typePopUp">
            <img *ngIf="data.typePopUp === 'titlePopUpInformationMessage'" [src]="data.imageUrl" class="nbz-image" />
            <p ngClass="title">{{ data.title }}</p>
            <div ngClass="subtitle-container">
                <p ngClass="subtitle" [innerHTML]="data.subTitle"></p>
                <p class="optional" *ngIf="data.optionalText">{{ data.optionalText }}</p>
            </div>
        </div>
        <div *ngIf="data.typePopUp === 'titlePopUpConfirmationMessage'" class="buttonsPopUp">
            <button *ngIf="data.secondButtonText" class="cancelDelete" (click)="cancel()" >{{ data.secondButtonText }}</button>
            <button class="blueButton" (click)="close()" >{{ data.firstButtonText? data.firstButtonText :'OK' }}</button>
        </div>
    </div>
</div>
