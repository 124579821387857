import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-pop-up-messages',
    templateUrl: './pop-up-messages.component.html',
    styleUrls: ['./pop-up-messages.component.scss'],
})
export class PopUpMessagesComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            imageUrl?: string;
            title: string;
            subTitle: string;
            optionalText?: string;
            firstButtonText?: string;
            secondButtonText?: string;
            typePopUp: string;
        }
    ,private dialogRef: MatDialogRef<PopUpMessagesComponent>) {}


    close(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}