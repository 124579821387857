import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations';

export const onSideNavChange = trigger('onSideNavChange', [
    state(
        'close',
        style({
            width: '50px',
            padding: '8px',
        })
    ),
    state(
        'open',
        style({
            'min-width': '270px',
            padding: '16px',
        })
    ),
    transition('close => open', animate('250ms ease-in')),
    transition('open => close', animate('250ms ease-in')),
]);

export const animateText = trigger('animateText', [
    state(
        'hide',
        style({
            display: 'none',
            opacity: 0,
        })
    ),
    state(
        'show',
        style({
            display: 'block',
            opacity: 1,
        })
    ),
    transition('close => open', animate('250ms ease-in')),
    transition('open => close', animate('50ms ease-out')),
]);
