import { Component, Inject, OnInit } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, combineLatest } from 'rxjs';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { VariableTypeEnum } from 'src/app/shared/models/enum/variableType.enum';
import { Tag } from 'src/app/shared/models/views-models/tag.model';
import { Weighted } from 'src/app/shared/models/views-models/weighted.model';
import { WeightedEntry } from 'src/app/shared/models/views-models/weightedEntry.model';
import { ProcessInputService } from 'src/app/shared/service/views-services/process.service';
import { TagService } from 'src/app/shared/service/views-services/tag.service';
import { WeightedService } from 'src/app/shared/service/views-services/weighted.service';
import { WeightedEntryService } from 'src/app/shared/service/views-services/weightedEntry.service';
import { getProject } from 'src/app/shared/utils/projectUtils';
import { getUserData } from 'src/app/shared/utils/userRole';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { DeleteDialogComponent } from '../../dialogs/delete-dialog/delete-dialog.component';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';
@Component({
    selector: 'app-add-weighted-dialog',
    templateUrl: './add-weighted-dialog.component.html',
    styleUrls: ['./add-weighted-dialog.component.scss'],
})
export class AddWeightedDialogComponent implements OnInit {
    successMessage = MessagesEnum.SuccessMessage;
    deleteMessage = MessagesEnum.DeleteMessage;
    failureMessage = MessagesEnum.FailureNameMessage;
    alertMessage = 'Não pode-se usar entradas iguais para uma ponderada';
    minEntriesAlert = 'Uma ponderada deve ter ao menos 2 entradas';
    formAlert = MessagesEnum.invalidFormMessage;

    weightedForm: UntypedFormGroup;
    weightedEntryForm: UntypedFormGroup;
    weightedEntries: UntypedFormArray;
    dialogRefMsg: any;

    weighted: Weighted = new Weighted();
    weightedEntriesArray: WeightedEntry[] = [];

    isEditing: boolean;
    user: any;

    fuzzyOptions = [];
    weightedNames = [];
    conditionOptions = [];
    previousFuzzy = [];
    previousCondition = [];

    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(
        private weightedService: WeightedService,
        private weightedEntryService: WeightedEntryService,
        private tagService: TagService,
        private processService: ProcessInputService,
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<AddWeightedDialogComponent>,
        public currentProjectService: CurrentProjectService,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    async ngOnInit() {
        this.user = getUserData();
        this.initForm();
        this.isEditing = false;

        const dataLoadedPromise = this.loadSelectAndInputsData();

        if (this.data !== null && this.data.id !== null && this.data.id !== undefined && this.data.id !== 'undefined') {
            this.createEditForm(this.data);
            this.isEditing = true;
        } else {
            this.createTwoWeightedEntries();
        }

        await dataLoadedPromise;
    }

    async loadSelectAndInputsData() {
        const [fuzzy, weightedVars, processVars, equationTags] = await Promise.all([
            this.tagService.getAllTagsByType(VariableTypeEnum.FUZZY).toPromise(),
            this.tagService.getAllTagsByType(VariableTypeEnum.WEIGHTED).toPromise(),
            this.processService.getAllProcessesByType(VariableTypeEnum.DIGITAL).toPromise(),
            this.tagService.getAllTagsByType(VariableTypeEnum.EQUATION).toPromise(),
        ]);

        this.fuzzyOptions.push(...fuzzy);

        const weightedNames = weightedVars.map((weighted) => weighted.name);
        if (this.data !== null) {
            const index = weightedNames.indexOf(this.data.name);
            if (index > -1) {
                weightedVars.splice(index, 1);
            }
        }
        this.fuzzyOptions.push(...weightedVars);
        this.fuzzyOptions.sort((a, b) => a.name.localeCompare(b.name));

        const processVarsResult = processVars.map((process) => process.tag);
        const equationTagsResult = equationTags.reduce((acc, cur) => acc.concat(cur), []);

        this.conditionOptions.push(...processVarsResult, ...equationTagsResult);
        this.conditionOptions.sort((a: Tag, b: Tag) => a.name.localeCompare(b.name));
    }

    initForm() {
        this.weightedForm = this.formBuilder.group({
            inputName: [null, [Validators.required, Validators.minLength(1)]],
            inputDesc: [null, [Validators.required, Validators.minLength(1)]],
            weightedEntries: this.formBuilder.array([], validateWeightSum()),
        });
        if (!this.user.permissions.canUpdate || this.currentProjectService.isRunningMode()) {
            this.weightedForm.disable();
        }
    }

    createTwoWeightedEntries() {
        for (let index = 0; index < 2; index++) {
            this.addWeightedEntry();
        }
    }

    createWeightedEntry() {
        if (this.currentProjectService.isRunningMode()) {
            return this.formBuilder.group({
                id: [{ value: null, disabled: true }],
                entry_tag: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1)]],
                condition_tag: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1)]],
                weight_value: [
                    { value: null, disabled: true },
                    [Validators.required, Validators.max(1), Validators.min(0)],
                ],
            });
        }
        return this.formBuilder.group({
            id: [],
            entry_tag: [null, [Validators.required, Validators.minLength(1)]],
            condition_tag: [null, [Validators.required, Validators.minLength(1)]],
            weight_value: [null, [Validators.required, Validators.max(1), Validators.min(0)]],
        });
    }

    addWeightedEntry(): void {
        this.weightedEntries = this.weightedForm.get('weightedEntries') as UntypedFormArray;
        this.weightedEntries.push(this.createWeightedEntry());
        this.previousFuzzy.push('');
        this.previousCondition.push('');
    }

    removeWeightedEntry(index): void {
        this.weightedEntries = this.weightedForm.get('weightedEntries') as UntypedFormArray;
        this.previousFuzzy.splice(index, 1);
        this.previousCondition.splice(index, 1);
        if (this.weightedEntries.length > 2) {
            this.weightedEntries.removeAt(index);
        } else {
            let conf = this.defaultDialog;
            conf.data.message = this.minEntriesAlert;
            this.openDialog(conf);
        }
    }

    resetFuzzyField(index) {
        let currentFuzzy = this.weightedEntries.value[index]['entry_tag'];
        if (currentFuzzy) {
            this.previousFuzzy[index] = currentFuzzy;
            this.weightedEntries.controls[index].patchValue({ entry_tag: '' });
        }
    }

    checkPreviousFuzzy(index) {
        let previousFuzzy = this.weightedEntries.value[index]['entry_tag']
            ? this.weightedEntries.value[index]['entry_tag']
            : this.previousFuzzy[index];
        if (previousFuzzy) {
            this.weightedEntries.controls[index].patchValue({ entry_tag: previousFuzzy });
        }
    }

    resetConditionField(index) {
        let currentCondition = this.weightedEntries.value[index]['condition_tag'];
        if (currentCondition) {
            this.previousCondition[index] = currentCondition;
            this.weightedEntries.controls[index].patchValue({ condition_tag: '' });
        }
    }

    checkPreviousCondition(index) {
        let previousCondition = this.weightedEntries.value[index]['condition_tag']
            ? this.weightedEntries.value[index]['condition_tag']
            : this.previousCondition[index];
        if (previousCondition) {
            this.weightedEntries.controls[index].patchValue({ condition_tag: previousCondition });
        }
    }

    createEditForm(data: any) {
        const id = data.id;
        this.weightedService.getWeighted(id).subscribe((weighted) => {
            this.weighted = weighted;

            this.weightedForm.patchValue({
                inputName: weighted.tag.name,
                inputDesc: weighted.tag.description,
            });
        });
        this.weightedEntryService.getWeightedEntriesByWeightedId(id).subscribe((weightedEntries) => {
            this.weightedEntries = this.weightedForm.get('weightedEntries') as UntypedFormArray;

            weightedEntries.forEach((weightedEntry) => {
                this.weightedEntryForm = this.createWeightedEntry();
                this.weightedEntryForm.patchValue({
                    entry_tag: weightedEntry.entry_tag.name,
                    condition_tag: weightedEntry.condition_tag.name,
                    weight_value: weightedEntry.weight_value,
                    id: weightedEntry.id,
                });
                if (!this.user.permissions.canUpdate) {
                    this.weightedEntryForm.disable();
                } else if (!this.user.permissions.canCreate) {
                    this.weightedEntryForm.controls['entry_tag'].disable();
                }
                this.weightedEntries.push(this.weightedEntryForm);
            });
        });
    }

    deleteWeighted() {
        const conf = this.defaultDialog;

        const confirmDelete = {
            component: DeleteDialogComponent,
            width: 'auto',
            height: 'auto',
            panelClass: 'pop-up-dialog-container',
            data: {
                message: '',
            },
        };

        this.openDialog(confirmDelete);
        this.dialogRefMsg.afterClosed().subscribe((result) => {
            if (result) {
                this.weightedService.deleteWeighted(this.weighted.id).subscribe((dependencies) => {
                    if (dependencies.length > 0) {
                        conf.width = 'auto';
                        conf.height = 'auto';
                        var dependencieNames = '';
                        dependencies.forEach((tag) => {
                            dependencieNames += tag.name + ', ';
                        });
                        dependencieNames = dependencieNames.slice(0, -2) + '.';
                        conf.data.message =
                            'Conflito ao deletar. Esta ponderada é usada como entrada em: ' + dependencieNames;
                    } else {
                        conf.data.message = this.deleteMessage;
                    }
                    this.openDialog(conf);
                });
            }
        });
    }

    async onSubmit() {
        let conf = this.defaultDialog;
        this.weightedEntries = this.weightedForm.get('weightedEntries') as UntypedFormArray;
        this.validateTags();

        if (this.validateForm() && this.user.permissions.canUpdate) {
            this.transformDataToWeighted();
            if (!this.validateWeightedEntries()) {
                conf.data.message = this.alertMessage;
                this.openDialog(conf);
            } else if (this.weightedEntries.length < 2) {
                conf.data.message = this.minEntriesAlert;
                this.openDialog(conf);
            } else if (!this.isEditing) {
                this.weightedService.addWeighted(this.weighted).subscribe(
                    (weighted) => {
                        if (weighted) {
                            this.weighted = weighted;
                            conf.data.message = this.successMessage;
                        } else {
                            conf.data.message = this.failureMessage;
                        }
                        this.openDialog(conf);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            } else if (this.isEditing) {
                this.weightedService.updateWeighted(this.weighted).subscribe(
                    (response) => {    
                        if (response.weighted) {
                            this.weighted = response.weighted;
                            conf.data.message = this.successMessage;
                        } else {
                            conf.data.message = response.error || this.failureMessage;
                        }
                        this.openDialog(conf);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            } else {
                conf.data.message = this.alertMessage;
                this.openDialog(conf);
            }
        } else {
            conf.data.message = this.formAlert;
            this.openDialog(conf);
        }
    }

    transformDataToWeighted() {
        const weightedFormData = this.weightedForm.getRawValue();
        const weightedEntriesList = weightedFormData.weightedEntries;

        if (this.data !== null) {
            this.weighted.id = this.data.id;
        }

        this.weighted.tag.name = weightedFormData.inputName;
        this.weighted.tag.description = weightedFormData.inputDesc;

        this.validateTags();
        this.weighted.weighted_entries = this.insertWeightedEntries(weightedEntriesList);
    }

    insertWeightedEntries(weightedEntries: any) {
        var weightedEntriesArray: WeightedEntry[] = [];
        for (let weightedEntry of weightedEntries) {
            let newWeightedEntry: WeightedEntry = new WeightedEntry();

            newWeightedEntry.id = weightedEntry.id;

            newWeightedEntry.entry_tag.id = this.getTagIDbyName(weightedEntry.entry_tag, VariableTypeEnum.FUZZY);
            newWeightedEntry.condition_tag.id = this.getTagIDbyName(
                weightedEntry.condition_tag,
                VariableTypeEnum.PROCESS
            );

            newWeightedEntry.weight_value = weightedEntry.weight_value;
            weightedEntriesArray.push(newWeightedEntry);
        }
        return weightedEntriesArray;
    }

    getTagIDbyName(name: string, type: string) {
        let tag;
        if (type == VariableTypeEnum.PROCESS) {
            tag = this.conditionOptions.find((process) => process.name == name);
        } else {
            tag = this.fuzzyOptions.find((fuzzy) => fuzzy.name == name);
        }
        return tag.id;
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });

        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (options.data.message == this.successMessage || options.data.message == this.deleteMessage) {
                this.dialogRef.close();
            }
        });
    }

    validateWeightedEntries() {
        let weightedFormData = this.weightedForm.getRawValue();
        let weightedEntriesList = weightedFormData.weightedEntries;
        let verifiedEntries = [];
        let valid = true;
        weightedEntriesList.forEach((weightedEntry) => {
            let weightedEntryId = weightedEntry.entry_tag;
            if (verifiedEntries.indexOf(weightedEntryId) == -1) {
                verifiedEntries.push(weightedEntryId);
            } else {
                valid = false;
            }
        });
        return valid;
    }

    validateTags() {
        let entries = this.weightedEntries;
        for (let entry of entries.controls.values()) {
            if (!this.fuzzyOptions.find((fuzzy) => fuzzy.name == entry.get('entry_tag').value)) {
                entry.patchValue({ entry_tag: '' });
            }
            if (!this.conditionOptions.find((process) => process.name == entry.get('condition_tag').value)) {
                entry.patchValue({ condition_tag: '' });
            }
        }
    }

    validateForm() {
        if (this.weightedForm.valid) {
            return true;
        } else {
            return false;
        }
    }

    getControls() {
        return (this.weightedForm.get('weightedEntries') as UntypedFormArray).controls;
    }

    close() {
        this.dialogRef.close();
    }
}

export function validateWeightSum(): ValidatorFn {
    return (formArray: UntypedFormArray): { [key: string]: any } | null => {
        let valid: boolean = false;
        let sum = 0;
        formArray.controls.forEach((x: UntypedFormControl) => {
            sum += x.value.weight_value;
            if (sum == 1) {
                valid = true;
            } else {
                valid = false;
            }
        });
        return valid ? null : { notValid: true };
    };
}
