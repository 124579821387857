<mat-card appearance="outlined">
    <mat-card-header>
        <div class="title-home" data-cy="weighted">
            <mat-card-title>Ponderadas</mat-card-title>
            <button
                class="customButton add-button"
                mat-raised-button
                type="button"
                (click)="add()"
                *ngIf="user.permissions.canCreate && !isShow()"
                data-cy="add"
            >
                ADICIONAR
            </button>
        </div>
    </mat-card-header>

    <mat-divider></mat-divider>

    <br />

    <mat-card-content>
        <mat-form-field appearance="outline">
            <mat-label>Buscar Ponderadas</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" />
            <mat-icon matSuffix><i class="material-icons">search</i></mat-icon>
        </mat-form-field>

        <div class="table-container mat-elevation-z8">
            <table mat-table matSort [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                    <mat-cell *matCellDef="let element"
                        ><a (click)="selectElement(element)">{{ element.name }}</a></mat-cell
                    >
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>Descrição</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Ações</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button
                            mat-button
                            (click)="selectElement(element)"
                            *ngIf="user.permissions.canUpdate && currentProjectService.isBuildingMode(); else visibilityButton" matTooltip="Editar" matTooltipPosition="above"
                        >
                            <i class="material-icons">edit</i>
                        </button>
                        <button mat-button (click)="deleteElement(element)" *ngIf="user.permissions.canDelete && !isShow()" matTooltip="Excluir" matTooltipPosition="above">
                            <i class="material-icons">delete</i>
                        </button>
                        <ng-template #visibilityButton>
                            <button mat-button (click)="selectElement(element)" matTooltip="Visualizar" matTooltipPosition="above">
                                <i class="material-icons">visibility</i>
                            </button>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </table>
        </div>
    </mat-card-content>
</mat-card>
