<a 
  class="version-container" 
  [ngClass]="{'disableVersionButton': verifyProjectVersions() === 1}" 
  (click)="selectVersion()"
  (mouseenter)="onHover(true)" 
  (mouseleave)="onHover(false)"
>
    <span
      [ngClass]="{'selected': selectedVersion === 'BUILDING', 'not-selected': selectedVersion !== 'BUILDING'}">
      BUILDING
    </span>

    <span
      [ngClass]="{'selected': selectedVersion === 'RUNNING', 'not-selected': selectedVersion !== 'RUNNING'}">
      RUNNING
    </span>
</a>
<div 
class="tooltip" 
*ngIf="showTooltip && verifyProjectVersions() === 1" [innerHTML]="tooltipMessage">

</div>
<app-loader *ngIf="isSpinnerActive" [loaderText]="loaderMessage"></app-loader>
