import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesEnum } from 'src/app/shared/models/enum/messages.enum';
import { Project } from 'src/app/shared/models/views-models/project.model';
import { LocalStorageService } from 'src/app/shared/service/views-services/localstorage.service';
import { ProjectService } from 'src/app/shared/service/views-services/project.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { CurrentProjectService } from 'src/app/shared/service/views-services/current-project.service';

@Component({
    selector: 'app-project-dialog',
    templateUrl: './project-dialog.component.html',
    styleUrls: ['./project-dialog.component.scss'],
})
export class ProjectDialogComponent implements OnInit {
    isEditing = false;
    projectDefault = false;
    projectForm: UntypedFormGroup;
    project: Project = new Project();

    dialogRefMsg: any;
    defaultDialog = {
        component: ConfirmDialogComponent,
        width: 'auto',
        height: 'auto',
        panelClass: 'pop-up-dialog-container',
        data: {
            message: '',
        },
    };

    constructor(
        private formBuilder: UntypedFormBuilder,
        private projectService: ProjectService,
        private lsService: LocalStorageService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<ProjectDialogComponent>,
        public currentProjectService: CurrentProjectService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.initForm();
        if (this.data) {
            this.createEditForm();
        }
    }

    initForm() {
        this.projectForm = this.formBuilder.group({
            name: [null, [Validators.required]],
            description: [null],
            area: [null],
        });
        this.getCurrentProjectVersionType() === 'RUNNING' && this.projectForm.disable();
    }

    createEditForm() {
        this.isEditing = true;
        this.projectDefault = this.data.projectDefault;
        this.projectForm.patchValue({
            name: this.data.name,
            description: this.data.description,
            area: this.data.area,
        });
    }

    setDefaultProject() {
        this.projectDefault = true;
    }

    onSubmit() {
        if (this.projectForm.valid) {
            this.transformDataToDto();
            if (!this.isEditing) {
                this.projectService.newProject(this.project).subscribe((response) => {
                    this.project = response;
                    this.projectDefault = this.project.projectDefault;
                    if (this.projectDefault) {
                        this.lsService.reset(this.project);
                    }
                    this.defaultDialog.data.message = MessagesEnum.SuccessMessage;
                    this.openDialog(this.defaultDialog);
                });
            } else {
                this.project.id = this.data.id;
                this.projectService.updateProject(this.project).subscribe((response) => {
                    this.project = response;
                    this.projectDefault = this.project.projectDefault;
                    if (this.projectDefault) {
                        this.lsService.reset(this.project);
                    }
                    this.defaultDialog.data.message = MessagesEnum.SuccessEditMessage;
                    this.openDialog(this.defaultDialog);
                    this.dialogRefMsg.afterClosed().subscribe(() => {
                        if (this.currentProjectService.getCurrentProject().id === this.project.id) {
                            this.currentProjectService.setCurrentProject(this.project);
                        }
                    });
                });
            }
        } else {
            this.defaultDialog.data.message = MessagesEnum.invalidFormMessage;
            this.openDialog(this.defaultDialog);
        }
    }

    transformDataToDto() {
        let formData = this.projectForm.getRawValue();
        this.project.name = formData.name;
        this.project.description = formData.description;
        this.project.area = formData.area;
        this.project.projectDefault = this.projectDefault;
    }

    close() {
        this.dialogRef.close(true);
    }

    openDialog(options: any): void {
        this.dialogRefMsg = this.dialog.open(options.component, {
            panelClass: options.panelClass,
            width: options.width,
            height: options.height,
            data: options.data,
        });
        this.dialogRefMsg.afterClosed().subscribe(() => {
            if (
                [MessagesEnum.SuccessEditMessage.valueOf(), MessagesEnum.SuccessMessage.valueOf()].includes(
                    this.defaultDialog.data.message
                )
            ) {
                this.close();
            }
        });
    }
    getCurrentProjectVersionType(): string {
        return this.data? this.data.versionType : '';
    }
}
